const topMenuFix = () => {
  const topMenuToFix = document.querySelector(".header");
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  if (scrollTop > 20) {
    topMenuToFix.classList.add("header__fixed");

  }
  if (scrollTop <= 20) {
    topMenuToFix.classList.remove("header__fixed");

  }
};

export default topMenuFix;