
import topMenuFix from './modules/topMenuFix.js';
import burger from './modules/burger.js';
document.addEventListener('DOMContentLoaded', () => {
  burger();
});

window.addEventListener('scroll', function () {
  topMenuFix();


});