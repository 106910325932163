const burger = () => {
  const burgerBtn = document.querySelector('.burger-btn__line'),
    mobileMenu = document.querySelector('.header__menu'),
    mobileMenuClose = document.querySelector('.burger-btn_close');

  burgerBtn.addEventListener('click', function () {

    mobileMenu.classList.add('show-m');
    document.body.style.overflow = "hidden";
    burgerBtn.classList.add('hide');
    mobileMenuClose.classList.add('show');

  });
  mobileMenuClose.addEventListener('click', function () {


    mobileMenu.classList.remove('show-m');
    document.body.style.overflow = "";
    mobileMenuClose.classList.remove('show');
    burgerBtn.classList.remove('hide');
  });
  mobileMenu.addEventListener('click', function () {

    mobileMenu.classList.remove('show-m');
    document.body.style.overflow = "";

  });

}

export default burger;